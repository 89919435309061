import React from 'react';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import SEO from 'Components/SEO';

import LaboratoryList from 'Components/laboratory/List';

export default ({ data }) => {
  const { edges: biophysicsPlatform } = data.allMarkdownRemark;
  const biophysicsDescription = data.description;

  return (
    <PageWrapper>
      <SEO title="Biophysics Platform" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Biophysics Platform</h1>
        { !!biophysicsDescription && (
          <div className="page__content page__content--biophysics" dangerouslySetInnerHTML={ { __html: biophysicsDescription.html } } />
        ) }
        <LaboratoryList labs={ biophysicsPlatform } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "biophysics_platform"},
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            position
            description
            url
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    description: markdownRemark(
      frontmatter: {
        templateKey: {eq: "biophysics_description"},
      }
    ) {
      html
    }
  }
`;
